<template>
  <div justify="end">
    <v-dialog
      v-model="isVisibleShow"
      max-width="1200px"
    >
      <v-card elevation="0">
        <v-card-title class="pb-4">
          <span class="text-h3">{{ $t("product") }}</span>
        </v-card-title>
        <v-card-text class="pa-0 grey lighten-4">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
                class="px-10"
              >
                <v-img
                  v-if="product.image"
                  :src="product.image.url"
                />
                <v-img
                  v-else
                  src="@/assets/product.png"
                />
              </v-col>
              <v-col
                cols="12"
                sm="8"
                md="8"
              >
                <v-card elevation="0">
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>{{ $t("id") }}</td>
                            <td>
                              {{ product?.generated_code ?? product?.code }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("name") }}</td>
                            <td>{{ product?.name }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("barcode") }}</td>
                            <td>{{ product.barcode }}</td>
                          </tr>

                          <tr>
                            <td>{{ $t("purchase_price") }}</td>
                            <td>{{ product.purchase_price }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("price") }}</td>
                            <td>{{ product.price }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("category") }}</td>
                            <td>
                              {{ currentCategory?.name || "_" }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("sub_category") }}</td>
                            <td>
                              {{
                                product?.category
                                  ? product?.category?.name
                                  : "_"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("brand") }}</td>
                            <td>
                              {{ product.brand ? product?.brand?.name : "_" }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="product.hubs && product.hubs.length > 0">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-card elevation="0">
                  <v-card-title class="text-left">
                    <span>{{ $t("hubs") }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("name") }}
                            </th>
                            <th class="text-left">
                              {{ $t("quantities") }}
                            </th>
                            <th class="text-left">
                              {{ $t("min_quantity") }}
                            </th>
                            <th class="text-left">
                              {{ $t("max_quantity") }}
                            </th>
                            <th class="text-left">
                              {{ $t("days_before_expiry") }}
                            </th>
                            <th class="text-left">
                              {{ $t("alert_status") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in product.hubs"
                            :key="item.id"
                            :class="itemBG(item)"
                          >
                            <td>{{ item.name }}</td>
                            <td>{{ item.qte_in_hub || 0 }}</td>
                            <td
                              :class="
                                item.qte_in_hub <= item.min_qte_in_hub * 1.2 &&
                                item.alert_status == 'active'
                                  ? 'font-weight-bold'
                                  : ''
                              "
                            >
                              {{ item.min_qte_in_hub }}
                            </td>
                            <td
                              :class="
                                item.qte_in_hub >= item.max_qte_in_hub * 0.9 &&
                                item.alert_status == 'active'
                                  ? 'font-weight-bold'
                                  : ''
                              "
                            >
                              {{ item.max_qte_in_hub }}
                            </td>
                            <td>{{ item.days_before_expiry }}</td>
                            <td>{{ item.alert_status }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="closeForm()"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isLoadingProduct: "products/isLoadingProduct",
      product: "products/product",
      currentItem: "productAlerts/getItem",
      categories: "categories/listRoots",
    }),

    isVisibleShow: {
      get() {
        return this.$store.state.productAlerts.isVisibleShow;
      },
      set(value) {
        this.$store.commit("productAlerts/IS_VISIBLE_SHOW", value);
      },
    },

    currentCategory() {
      return this.categories.find(
        (item) => item.id == this.product.category_parent_id
      );
    },
  },

  data() {
    return {};
  },
  methods: {
    closeForm() {
      this.$store.dispatch("productAlerts/closeForm");
    },

    itemBG(item) {
      if (item.alert_status == "inactive") return;
      if (
        item.qte_in_hub <= item.min_qte_in_hub ||
        item.qte_in_hub >= item.max_qte_in_hub
      )
        return "deep-orange lighten-5 red--text text--darken-1";
      else if (
        item.qte_in_hub <= item.min_qte_in_hub * 1.2 ||
        item.qte_in_hub >= item.max_qte_in_hub * 0.9
      )
        return "orange lighten-5";
      return "green lighten-5";
    },
  },
};
</script>
